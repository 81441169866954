import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSpring, animated } from 'react-spring';

import { imgAnimationConfig, tagLineAnimationConfig, mainTextAnimationConfig } from './animations';

import welcomingEducator1x from '../../../assets/images/educators/educator-welcoming.png';
import welcomingEducator2x from '../../../assets/images/educators/educator-welcoming@2x.png';
import styles from './styles.module.css';

function ImageWelcoming() {
  const { t } = useTranslation();

  const imgStyles = useSpring(imgAnimationConfig);

  const txtStyles = useSpring(tagLineAnimationConfig);

  const mainTxtStyles = useSpring(mainTextAnimationConfig);

  return (
    <div className={styles.imageBlock}>
      <animated.img
        src={welcomingEducator1x}
        srcSet={`${welcomingEducator1x} 1x, ${welcomingEducator2x} 2x`}
        className={clsx(styles.educatorImg, styles.welcomingEducatorImg)}
        alt=""
        aria-hidden="true"
        style={imgStyles}
      />
      <h1 className={styles.tagLine}>
        <animated.span style={txtStyles} className={styles.relativeTagLine}>
          {t('main.tagLine.welcoming')}
        </animated.span>
        <animated.span style={mainTxtStyles} className={styles.mainTagLine}>
          {t('main.andProud')}
        </animated.span>
      </h1>
    </div>
  );
}

export default ImageWelcoming;
