import React from 'react';
import i18next from 'i18next';

import logo1x from '../../../assets/images/SDJ-logo_Blanc.png';
import logo2x from '../../../assets/images/SDJ-logo_Blanc@2x.png';
import styles from './styles.module.css';

function TopBanner() {
  return (
    <div className={styles.root}>
      <a href={i18next.t('links.soleilDeJeannot.home')} title={i18next.t('soleilDeJeannot')}>
        <img
          src={logo1x}
          srcSet={`${logo1x} 1x, ${logo2x} 2x`}
          alt={i18next.t('logo.cpe')}
          width="220"
          height="135"
          className={styles.logo}
        />
      </a>
    </div>
  );
}

export default TopBanner;
