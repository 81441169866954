import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import './assets/styles/index.css';
import i18n from './i18n';

import Landing from './react/Landing';
import reportWebVitals from './reportWebVitals';

import bloomingEducator1x from './assets/images/educators/educator-blooming.png';
import bloomingEducator2x from './assets/images/educators/educator-blooming@2x.png';
import creativeEducator1x from './assets/images/educators/educator-creative.png';
import creativeEducator2x from './assets/images/educators/educator-creative@2x.png';
import involvedEducator1x from './assets/images/educators/educator-involved.png';
import involvedEducator2x from './assets/images/educators/educator-involved@2x.png';
import partnerEducator1x from './assets/images/educators/educator-partner.png';
import partnerEducator2x from './assets/images/educators/educator-partner@2x.png';
import welcomingEducator1x from './assets/images/educators/educator-welcoming.png';
import welcomingEducator2x from './assets/images/educators/educator-welcoming@2x.png';

// Prefetch images
const imagesPreload: string[] = [
  bloomingEducator1x,
  bloomingEducator2x,
  creativeEducator1x,
  creativeEducator2x,
  involvedEducator1x,
  involvedEducator2x,
  partnerEducator1x,
  partnerEducator2x,
  welcomingEducator1x,
  welcomingEducator2x,
];
imagesPreload.forEach((image: string) => {
  const newImage = new Image();
  newImage.src = image;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window[image] = newImage;
});

if (process.env.REACT_APP_SENTRY_DNS) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Landing />
    </I18nextProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
