import React from 'react';
import styles from './styles.module.css';

type ButtonProps = {
  link: string;
  title: string;
  text: string;
};

function Button({ link, title, text }: ButtonProps) {
  return (
    <a href={link} className={styles.button} title={title}>
      {text}
    </a>
  );
}

export default Button;
