export default {
  hello: 'Bonjour',
  soleilDeJeannot: 'Soleil de Jeannot',
  links: {
    soleilDeJeannot: {
      home: 'http://soleildejeannot.ca',
      apply: 'https://soleildejeannot.ca/offres-demploi/',
      visit: 'https://soleildejeannot.ca/installation-corbusier/',
    },
  },
  logo: {
    cpe: 'Logo - CPE Soleil de Jeannot',
    concilivi: 'Logo - Sceau concilivi',
  },
  main: {
    tagLine: {
      partner: 'Complice',
      blooming: 'Épanouie',
      welcoming: 'Accueillante',
      creative: 'Créative',
      involved: 'Impliquée',
    },
    andProud: '& Fière',
  },
  goToCPE: {
    line1:
      'Le CPE Le Soleil de Jeannot est présentement en développement et souhaite t’accueillir dans son équipe éducative.',
    line2:
      'Joins-toi aux 40 fières professionnelles de la petite enfance épanouies, complices, créatives, impliquées et accueillantes! ',
    buttonApplyNow: 'Postuler maintenant',
    buttonVisit: 'Visiter nos installations',
  },
};
