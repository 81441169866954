import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

// import logo1x from '../../../assets/images/SDJ-logo_Bleu.png';
// import logo2x from '../../../assets/images/SDJ-logo_Bleu@2x.png';
import styles from './styles.module.css';
import Button from '../Button';

function TopBanner() {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <p className={clsx(styles.text, styles.bloc1)}>{t('goToCPE.line1')}</p>
      <p className={clsx(styles.text, styles.bloc2)}>{t('goToCPE.line2')}</p>
      <div className={styles.buttonWrapper}>
        <Button
          link={t('links.soleilDeJeannot.apply')}
          title={t('goToCPE.buttonApplyNow')}
          text={t('goToCPE.buttonApplyNow')}
        />
        <div className={styles.buttonSpacer} />
        <Button
          link={t('links.soleilDeJeannot.visit')}
          title={t('goToCPE.buttonVisit')}
          text={t('goToCPE.buttonVisit')}
        />
      </div>
    </div>
  );
}

export default TopBanner;
