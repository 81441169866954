import React from 'react';
import i18next from 'i18next';

import logo1x from '../../../assets/images/SDJ-logo_Bleu.png';
import logo2x from '../../../assets/images/SDJ-logo_Bleu@2x.png';
import styles from './styles.module.css';

function TopBanner() {
  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <a href={i18next.t('links.soleilDeJeannot.home')} title={i18next.t('soleilDeJeannot')}>
          <img
            src={logo1x}
            srcSet={`${logo1x} 1x, ${logo2x} 2x`}
            alt={i18next.t('logo.cpe')}
            height="77"
            width="125"
            className={styles.logo}
          />
        </a>
      </div>
    </div>
  );
}

export default TopBanner;
