import React from 'react';
import TopBanner from './components/TopBanner';
import GoToCPE from './components/GoToCPE';
import Main from './components/Main';
import BottomBanner from './components/BottomBanner';

import styles from './Landing.module.css';

function Landing() {
  return (
    <div className={styles.root}>
      <TopBanner />
      <Main />
      <GoToCPE />
      <BottomBanner />
    </div>
  );
}

export default Landing;
