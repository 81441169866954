import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import sceauConcilivi1x from '../../../assets/images/sceau_concilivi.png';
import sceauConcilivi2x from '../../../assets/images/sceau_concilivi@2x.png';
import styles from './styles.module.css';

import ImageWelcoming from './ImageWelcoming';
import ImagePartner from './ImagePartner';
import ImageCreative from './ImageCreative';
import ImageBlooming from './ImageBlooming';
import ImageInvolved from './ImageInvolved';

const EducatorImage = {
  Welcoming: 'welcoming',
  Partner: 'partner',
  Creative: 'creative',
  Blooming: 'blooming',
  Involved: 'involved',
};

function Main() {
  const { t } = useTranslation();
  const [showImage, setShowImage] = useState(EducatorImage.Partner);

  useEffect(() => {
    setTimeout(() => {
      if (showImage === EducatorImage.Partner) setShowImage(EducatorImage.Blooming);
      if (showImage === EducatorImage.Blooming) setShowImage(EducatorImage.Welcoming);
      if (showImage === EducatorImage.Welcoming) setShowImage(EducatorImage.Creative);
      if (showImage === EducatorImage.Creative) setShowImage(EducatorImage.Involved);
      if (showImage === EducatorImage.Involved) setShowImage(EducatorImage.Partner);
    }, 3300);
  });

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        {showImage === EducatorImage.Welcoming && <ImageWelcoming key="welcoming" />}
        {showImage === EducatorImage.Partner && <ImagePartner key="partner" />}
        {showImage === EducatorImage.Creative && <ImageCreative key="creative" />}
        {showImage === EducatorImage.Blooming && <ImageBlooming key="blomming" />}
        {showImage === EducatorImage.Involved && <ImageInvolved key="involved" />}
        <img
          src={sceauConcilivi1x}
          srcSet={`${sceauConcilivi1x} 1x, ${sceauConcilivi2x} 2x`}
          alt={t('logo.concilivi')}
          className={styles.concilivi}
        />
      </div>
    </div>
  );
}

export default Main;
