import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSpring, animated } from 'react-spring';

import { imgAnimationConfig, tagLineAnimationConfig, mainTextAnimationConfig } from './animations';

import bloomingEducator1x from '../../../assets/images/educators/educator-blooming.png';
import bloomingEducator2x from '../../../assets/images/educators/educator-blooming@2x.png';
import styles from './styles.module.css';

function ImageBlooming() {
  const { t } = useTranslation();

  const imgStyles = useSpring(imgAnimationConfig);

  const txtStyles = useSpring(tagLineAnimationConfig);

  const mainTxtStyles = useSpring(mainTextAnimationConfig);

  return (
    <div className={styles.imageBlock}>
      <animated.img
        src={bloomingEducator1x}
        srcSet={`${bloomingEducator1x} 1x, ${bloomingEducator2x} 2x`}
        className={clsx(styles.educatorImg, styles.bloomingEducatorImg)}
        alt=""
        aria-hidden="true"
        style={imgStyles}
      />
      <h1 className={styles.tagLine}>
        <animated.span style={txtStyles} className={styles.relativeTagLine}>
          {t('main.tagLine.blooming')}
        </animated.span>
        <animated.span style={mainTxtStyles} className={styles.mainTagLine}>
          {t('main.andProud')}
        </animated.span>
      </h1>
    </div>
  );
}

export default ImageBlooming;
