/* eslint-disable @typescript-eslint/no-explicit-any */
import { easings } from 'react-spring';

export const imgAnimationConfig = {
  to: async (next: any) => {
    await next({ delay: 150 });
    await next({ opacity: 1, translateX: 0 });
    await next({ delay: 2500 });
    await next({ opacity: 0, translateX: 25 });
    await next({ delay: 50 });
  },
  from: { opacity: 0, translateX: -25 },
  config: { duration: 350, easing: easings.easeInOutBounce },
};

export const tagLineAnimationConfig = {
  to: async (next: any) => {
    await next({ delay: 250 });
    await next({ opacity: 1, translateX: 0 });
    await next({ delay: 2350 });
    await next({ opacity: 0, translateX: 25 });
    await next({ delay: 50 });
  },
  from: { opacity: 0, translateX: -25 },
  config: { duration: 350, easing: easings.easeInOutBounce },
};

export const mainTextAnimationConfig = {
  to: async (next: any) => {
    await next({ delay: 325 });
    await next({ opacity: 1, right: 0 });
    await next({ delay: 2230 });
    await next({ opacity: 0, right: -35 });
    await next({ delay: 50 });
  },
  from: { opacity: 0, right: 35 },
  config: { duration: 350, easing: easings.easeInOutBounce },
};
